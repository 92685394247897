/*
 * @Description: 
 * @Author: luqisheng
 * @Date: 2024-03-25 15:03:06
 * @LastEditTime: 2024-03-25 15:04:09
 * @LastEditors: luqisheng
 */
// 接口前缀
export const PREFIX = {
  XY: "xy-api", // 小娱儿
  XYB: "xy-api-b/b", // 小娱儿 商品b
  XYP: "xy-api-b/p", // 小娱儿 商家
};

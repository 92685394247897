/*
 * @Description:
 * @Author: luqisheng
 * @Date: 2024-03-25 18:12:36
 * @LastEditTime: 2024-04-28 13:37:45
 * @LastEditors: luqisheng
 */
import axios from "axios";
import { httpSign } from "./httpSign";
import Cookies from "js-cookie";
import { Message } from 'view-ui-plus';
// import { Message, Notice } from "view-ui-plus";
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 5000, // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  async (config) => {
    // 可以在这里添加请求头等信息
    // if (store.getters.token) {
    //   config.headers['X-Token'] = getToken();
    // }
    // 接入验签
    config.headers[`X-Content-Security`] =
      (await httpSign(config.url, config.params, config.method, config.data)) ||
      "";
    config.headers["Accept-Language"] = Cookies.get("i18n-locale") || "zh-CN";
    if (config.method === "get" && config.params) {
      let url = config.url;
      url += "?";
      const keys = Object.keys(config.params);
      for (const key of keys) {
        if (config.params[key] !== void 0 && config.params[key] !== null) {
          url += `${key}=${encodeURIComponent(config.params[key])}&`;
        }
      }
      url = url.substring(0, url.length - 1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  (error) => {
    // 请求错误处理
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 对响应数据做处理，例如只返回data部分
    const res = response.data;
    if (res.code !== 200) {
      //   Message({
      //     message: res.message,
      //     type: "error",
      //     duration: 5 * 1000,
      //   });
      // 50001: 非法的token; 50002: 其他客户端错误; 50003: 认证失败; 50004: 授权失败; 50005: 未找到用户
      if (
        res.code === 50001 ||
        res.code === 50002 ||
        res.code === 50003 ||
        res.code === 50004 ||
        res.code === 50005
      ) {
        // 移除token
        // resetToken();
        // 跳转到登录页面
        location.reload();
      }
      Message.error({
        content: res.message || 'error',
        duration: 3,
      });
      return Promise.reject("error");
    } else {
      return res;
    }
    // return response;
  },
  (error) => {
    console.log("err" + error); // for debug
    // Message({
    //   message: error.message,
    //   type: "error",
    //   duration: 5 * 1000,
    // });
    return Promise.reject(error);
  }
);

export default service;

/*
 * @Description:
 * @Author: luqisheng
 * @Date: 2024-03-25 13:59:44
 * @LastEditTime: 2024-03-25 17:48:35
 * @LastEditors: luqisheng
 */
/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return "";
  return cleanArray(
    Object.keys(json).map((key) => {
      if (json[key] === undefined) return "";
      return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
    })
  ).join("&");
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = !url ? window.location.href : url;
  const search = url.substring(url.indexOf("?") + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @returns {Boolean}
 */
export function isXjb() {
  let vueAppEnv = process.env.VUE_APP_ENV;
  let list = ["test-xjb", "yee-dev", "yee-test", "yee-pre", "yee-pro"];
  return list.includes(vueAppEnv);
}

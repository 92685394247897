/*
 * @Description:
 * @Author: luqisheng
 * @Date: 2024-03-25 13:59:44
 * @LastEditTime: 2024-04-26 16:12:24
 * @LastEditors: luqisheng
 */
import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home/index.vue";
import login from "../views/login/index.vue";

const routes = [
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
];
let publicPath = ''
if(process.env.NODE_ENV === 'production' && process.env.VUE_APP_NODE_ENV === 'test') {
  publicPath = '/'
}
console.log(publicPath, 'publicPath');
const router = createRouter({
  history: createWebHistory(publicPath),
  routes
});

export default router;

/*
 * @Description:
 * @Author: luqisheng
 * @Date: 2024-03-25 13:59:44
 * @LastEditTime: 2024-04-28 13:48:38
 * @LastEditors: luqisheng
 */
import { createStore } from "vuex";
import { localeList } from "@/config";
import { getLanguageList } from "@/api/sys";
import Cookies from "js-cookie";
import { postUserAccountLogin } from "@/api/sys";
const savedLocaleKey = "i18n-locale";
export default createStore({
  state: {
    uuid: "",
    token: "",
    locale: "",
    languageList: [],
  },
  mutations: {},
  actions: {
    /**
     * @description 获取当前语言
     * */
    getLocale({ state }) {
      let locale;
      const savedLocale = localStorage.getItem(savedLocaleKey);
      // 先判断本地存储是否已有语言选择
      if (savedLocale) {
        locale = savedLocale;
      } else {
        // 自动识别的语言，本地没有该语言包，则设置为默认语言
        const navLang = navigator.language;
        let hasLang = ~localeList.findIndex((i) => i.locale === locale);
        locale = hasLang ? navLang : "zh-CN";
        // 将初次的语言保存在本地
        localStorage.setItem(savedLocaleKey, locale);
        Cookies.set(savedLocaleKey, locale);
      }
      state.locale = locale;
    },
    /**
     * @description 设置当前语言
     * */
    setLocale({ state }, { locale, vm }) {
      let hasLang = ~localeList.findIndex((i) => i.key === locale);
      localStorage.setItem(savedLocaleKey, hasLang ? locale : "zh-CN");
      Cookies.set(savedLocaleKey, hasLang ? locale : "zh-CN");
      // 设置当前语言
      state.locale = locale;
      location.reload();
    },
    getLanguageList({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        getLanguageList({ country: window.location.host })
          .then(async ({ data }) => {
            let items = data.items;
            state.languageList = items.map((lang) => {
              return localeList.find((i) => i.key === lang.key);
            });
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    login({ state }, data = {}) {
      return new Promise((resolve, reject) => {
        // 开始请求登录接口
        postUserAccountLogin(data)
          .then(async (res) => {
            try {
              let { jwt_token, user_info, tenant } = res.data;
              let uuid = user_info.user_id;
              let token = jwt_token.accessToken;
              state.uuid = uuid;
              state.token = token;
              Cookies.set("uuid", uuid);
              Cookies.set("token", token);
              if (!tenant) {
                reject("no_tenant");
              }
            } catch (error) {}
            // 结束
            resolve(null);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  modules: {},
});

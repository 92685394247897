/*
 * @Description:
 * @Author: luqisheng
 * @Date: 2024-03-26 16:53:16
 * @LastEditTime: 2024-04-10 16:22:08
 * @LastEditors: luqisheng
 */
import { SercetConfig } from "@/config";
import { SHA256, HmacSHA256, enc } from "crypto-js";
import JSEncrypt from "jsencrypt";
import { getTimestamp } from "./sysTime";

const { Base64 } = enc;
export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}
export function param(json) {
  if (!json) return "";
  return cleanArray(
    Object.keys(json).map((key) => {
      if (json[key] === undefined) return "";
      return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
    })
  ).join("&");
}
// 生成接口请求 验签header头X-Content-Security 参数
export const httpSign = async function (url, query, method, data) {
  url = url.split("-api-b")[1] || url.split("-api")[1] || "";
  method = method.toUpperCase();
  // 随机生成秘钥
  const key = uuidv4();
  // 请求链接上的query要是string
  let newQuery = "";
  let newData = "";
  // GET请求，拼接query  POST请求，json.stringify参数
  if (method === "GET" && query) {
    newQuery = param(query);
  } else newData = typeof data === "string" ? data : JSON.stringify(data);
  // 拿到当前时间戳
  // const timestamp = Date.parse(new Date().toString()) / 1000;
  const timestamp = await getTimestamp(url);
  // 对请求体做 SHA256 加密
  const hexString = SHA256(newData).toString();
  // 对请求结构数据以 \n分割后做 HmacSHA256 加密，再转成EncBase64.stringify输出
  const signatureData = [
    timestamp.toString(),
    method,
    url,
    newQuery,
    hexString,
  ];
  const signature = Base64.stringify(HmacSHA256(signatureData.join("\n"), key));
  // 使用公钥对 secretData 以;分割的字符串进行 rsa 加密
  const secretData = ["type=0", "key=" + btoa(key), "time=" + timestamp];
  const secret = rsaEncrypt(secretData.join(";"), SercetConfig.PublicKey);
  // res 就是所得的放在请求头 X-Content-Security 中的签名字符串
  const res =
    "key=" +
    SercetConfig.FingerPrint +
    ";secret=" +
    secret +
    ";signature=" +
    signature;
  return res;
};
// 随机生成一个密钥密码
const uuidv4 = function () {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = String(c) === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
// rsa加密 word加密数据 publicKey公钥
const rsaEncrypt = function (word, publicKey) {
  const encryptStr = new JSEncrypt();
  encryptStr.setPublicKey(publicKey);
  const encrypted = encryptStr.encrypt(word);
  return encrypted;
};

/*
 * @Description:
 * @Author: luqisheng
 * @Date: 2024-04-09 16:39:42
 * @LastEditTime: 2024-04-10 16:49:16
 * @LastEditors: luqisheng
 */
import axios from "axios";
// 获取服务器时间
const SysStartup = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.VUE_APP_BASE_API}/common-api/sys/startup`)
      .then((res) => {
        let { data } = res.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getTimestamp = async (url) => {
  if (url && url.indexOf("/sys/startup") !== -1)
    return Date.parse(new Date().toString()) / 1000;
  const absTime = sessionStorage.getItem("absTime");
  let timestamp = 0;
  if (!absTime || (absTime !== "0" && Math.abs(Number(absTime)) > 3200)) {
    const sysTime = await SysStartup();
    console.log(sysTime, "sysTime");
    if (sysTime.server_time) {
      timestamp = sysTime.server_time;
      const calculationAbsTime =
        Date.parse(new Date().toString()) / 1000 - sysTime.server_time;
      sessionStorage.setItem("absTime", String(calculationAbsTime));
    } else timestamp = Date.parse(new Date().toString()) / 1000;
  } else {
    timestamp =
      Date.parse(new Date().toString()) / 1000 - parseInt(String(absTime));
  }
  return timestamp || Date.parse(new Date().toString()) / 1000;
};
export { getTimestamp };

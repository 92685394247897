<!--
 * @Description: 
 * @Author: chenjunda
 * @Date: 2024-03-16 17:18:19
 * @LastEditTime: 2024-03-25 15:20:33
 * @LastEditors: luqisheng
-->
<template>
  <div>
    <Modal
      v-model="modal"
      :title="$t('page.login.set_account')"
      :loading="loading"
      class-name="vertical-center-modal"
    >
      <Form ref="formRef" :model="form" :rules="rule" label-position="top">
        <FormItem
          :label="$t('page.login.password_account')"
          prop="setPassword"
          class="model-inp"
        >
          <Input
            v-model="form.setPassword"
            type="password"
            password
            :placeholder="$t('page.login.password_msg4')"
          ></Input>
        </FormItem>
      </Form>
      <template #footer>
        <Button @click="ok" :disabled="!passwordLength">{{
          $t("page.login.ok")
        }}</Button>
      </template>
    </Modal>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { changePasswordGet } from "@/api/sys";

export default defineComponent({
  name: "password-dialog",
  data() {
    return {
      modal: false,
      loading: true,
      form: {
        setPassword: "", // 设置密码
      },
      userId: 0, // 用户id
      rule: {
        setPassword: [
          {
            required: true,
            message: this.$t("page.login.password_msg4"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    passwordLength() {
      let a = false;
      a = this.form.setPassword.length > 4;
      return a;
    },
  },
  methods: {
    open(id) {
      this.form.setPassword = "";
      this.userId = id;
      this.modal = true;
    },
    // 确认
    ok() {
      this.$refs.formRef["validate"]((valid) => {
        this.loading = false;
        this.$nextTick(() => {
          this.loading = true;
        });
        if (valid) {
          return new Promise((resolve, reject) => {
            changePasswordGet({
              passwd: this.form.setPassword,
              confirm_passwd: this.form.setPassword,
              user_id: this.userId,
            }).then((res) => {
              this.$emit("confirm", this.form.setPassword);
              resolve(res);
            });
          });
        }
      });
    },
    // 取消
    cancel() {
      this.modal = false;
    },
  },
});
</script>
<style lang="less">
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vertical-center-modal .ivu-modal {
  top: 0;
}
</style>
<style lang="less" scoped>
.model-message {
  height: 52px;
  line-height: 50px;
  background-color: #f0faff;
  border: 1px solid #abdcff;
  border-radius: 4px;
  color: #5a516e;
  font-size: 14px;
  padding-left: 5px;
}
.model-inp {
  margin-top: 15px;
  .inp-name {
    margin-bottom: 5px;
  }
  .inp-df {
    display: flex;
    align-items: "center";
    .df-icon {
      padding: 3px 5px 0 0;
    }
    .df-inp {
      flex: 1;
    }
    .df-del {
      color: red;
      padding: 0 30px;
    }
  }
}
</style>

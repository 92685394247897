<!--
 * @Description: 
 * @Author: luqisheng
 * @Date: 2023-08-22 10:58:54
 * @LastEditTime: 2024-04-11 10:39:00
 * @LastEditors: luqisheng
-->
<template>
  <div class="login">
    <view class="cur_lang">
      <Dropdown @on-click="handleCommand">
        <Icon type="ios-globe" />
        <span class="el-dropdown-link">
          {{ curLangName }}
        </span>
        <template #list>
          <DropdownMenu>
            <DropdownItem
              v-for="item in showLanguageList"
              :key="item.key"
              :name="item.key"
              :selected="locale === item.key"
            >
              <span>{{ item.label }}</span>
            </DropdownItem>
          </DropdownMenu>
        </template>
      </Dropdown>
    </view>
    <div class="login-left">
      <div class="login-left-logo">
        <img :src="logoUrl" class="logo-and-text" />
        <span class="text">{{ $t("page.login.h_title") }}</span>
      </div>
    </div>
    <div class="login-right">
      <div class="login-box" :style="{ height: isXjb ? '469px' : '557px' }">
        <!-- <div class="login-box-title">
          「{{ title }}」{{ $t("page.login.background_system") }}
        </div> -->
        <div class="login_tabs">
          <div
            :class="loginMethod == 'z' ? 'is_active' : ''"
            @click="changeLoginTab(3)"
          >
            {{ $t("page.login.loginMethodZ") }}
            <!-- 「咨客台」登录 -->
          </div>
          <div
            :class="loginMethod == 'h' ? 'is_active' : ''"
            @click="changeLoginTab(4)"
          >
            {{ $t("page.login.loginMethodH") }}
            <!-- 「商家后台」登录 -->
          </div>
        </div>
        <div class="login-content">
          <template v-if="avtiveTab == 1">
            <wxlogin
              v-if="loginWxOptions"
              class="wxlogin-code"
              :appid="loginWxOptions.appid"
              scope="snsapi_login"
              :redirect_uri="loginWxOptions.redirect_uri"
              :href="loginWxOptions.href"
            />
            <div class="wxlogin-desc">
              <img src="@/assets/login/ic-wechat.png" class="ic-wechat" />
              <span class="desc"> {{ $t("page.login.wx_other") }}</span>
            </div>
          </template>
          <template v-if="avtiveTab == 2">
            <Input
              v-model="form.account"
              size="large"
              :placeholder="$t('page.login.phone_msg')"
              class="input_wrap"
              @on-blur="userAccountPwd"
            >
              <template #prepend>
                <Select
                  v-model="phoneType"
                  style="width: 90px"
                  @on-change="userAccountPwd"
                >
                  <Option
                    style="float: left"
                    v-for="item in phoneTypeList"
                    :key="item.code"
                    :label="`+${item.code}`"
                    :value="item.code"
                  >
                    +{{ item.code }} {{ item.country }}
                  </Option>
                </Select>
              </template>
            </Input>
            <Input
              v-model="form.password"
              type="password"
              size="large"
              password
              :placeholder="$t('page.login.password_msg')"
              style="margin-top: 30px"
              class="input_wrap"
            />
            <Button
              type="primary"
              size="large"
              style="margin-top: 60px; display: block"
              class="input_wrap"
              @click="handleSubmit"
              >{{ $t("page.login.submit") }}</Button
            >
          </template>
        </div>
      </div>
      <div class="company">
        <div v-if="isXjb">遇樂國際控股有限公司</div>
        <div v-else>广州遇乐网络有限公司</div>
        <div class="copyright" v-if="isXjb">Copyright © 2024 hiyu.cc</div>
        <div class="copyright" v-else>
          Copyright2022｜xye.wang｜<a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            >粤ICP备17060322号-20</a
          >
        </div>
      </div>
    </div>
    <password ref="passwordRef" @confirm="passwordLogin"></password>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { LoginConfig } from "@/config";
import password from "./components/password.vue";
import wxlogin from "vue-wxlogin";
import { mapState, mapActions } from "vuex";
// import mixins from "../mixins";
import { isXjb } from "@/utils";
import { phoneCodeLictGet, userAccountPwdGet, getCountryList } from "@/api/sys";

export default defineComponent({
  name: "page-account-login",
  //   mixins: [mixins],
  components: { wxlogin, password },
  computed: {
    ...mapState(["token", "locale", "languageList"]),
    showLanguageList() {
      return this.languageList.filter((i) => i.key !== this.locale);
    },
    curLangName() {
      let item = this.languageList.find((i) => this.locale === i.key);
      let name = item ? item.label : "";
      return name;
    },
    title() {
      return isXjb() ? "hiyu.cc" : "小娱儿";
    },
    logoUrl() {
      return isXjb()
        ? require("@/assets/login/logo-and-text2.png")
        : require("@/assets/login/logo-and-text.png");
    },
  },
  data() {
    return {
      autoLogin: true,
      loginWxOptions: null,
      phoneType: "86",
      phoneTypeList: [],
      form: {
        account: "", // 手机号
        password: "", // 密码
      },
      setPassword: "", // 设置密码
      userId: 0, // 用户id
      avtiveTab: isXjb() ? 2 : 1, // 微信扫码: 1; 账号登录: 2;
      isXjb: isXjb(),
      loginMethod: "h", // h:商户后台登录， z：咨客台登录
      domainsList: null,
    };
  },
  created() {
    console.log(this.$store, "是否为国际版", isXjb());
    this.getCountryList();
    this.phoneCodeLic();
    this.$store.dispatch("getLanguageList");
  },
  methods: {
    ...mapActions(["setLocale", "login"]),
    handleCommand(locale) {
      if (locale === this.locale) return;
      this.setLocale({ locale, vm: this });
    },
    /**
     * @description 登录
     * 表单校验已有 View UI Plus 自动完成，如有需要修改，请阅读 View UI Plus 文档
     */
    handleSubmit() {
      let url = "";
      let item = this.domainsList.find((i) => this.loginMethod === i.platform);
      if (item && item.domain) {
        url = item.domain;
      } else {
        this.$Message.warning("登录异常");
        return;
      }
      this.userAccountPwd();
      const { account, password } = this.form;
      if (account == "") {
        this.$Message.error(this.$t("page.login.phone_msg"));
        return;
      }
      if (password == "") {
        this.$Message.error(this.$t("page.login.password_msg"));
        return;
      }
      this.login({
        account: `+${this.phoneType}-${account}`,
        password,
      }).then(() => {
        // 重定向对象不存在则返回顶层路径
        setTimeout(() => {
          window.open(`${url}/#/?token=${this.token}`, "_blank");
        }, 200);
      });
    },
    wxLoginCode() {
      var content = `.impowerBox .qrcode {width: 240px;margin-top:0;border:none;}.impowerBox .title {display: none;}.impowerBox .info {display: none;}`;
      var blob = new Blob([content], {
        type: "text/css;charset=utf-8",
      });
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      let that = this;
      reader.onload = function () {
        that.loginWxOptions = {
          appid: LoginConfig.LOGIN_APPID,
          redirect_uri: LoginConfig.LOGIN_REDIRECT_URI,
          href: this.result,
        };
      };
    },
    changeLoginTab(type) {
      switch (type) {
        case 3:
          this.loginMethod = "z";
          break;
        case 4:
          this.loginMethod = "h";
          break;

        default:
          this.avtiveTab = type;
          break;
      }
    },
    phoneCodeLic() {
      return new Promise((resolve) => {
        phoneCodeLictGet({ country: window.location.host }).then(({ data }) => {
          let defItem = data.items.find((i) => i.is_default) || data.items[0];
          this.phoneTypeList = data.items || [];
          this.phoneType = defItem ? defItem.code : "";
          resolve(data);
        });
      });
    },
    userAccountPwd() {
      return new Promise((resolve) => {
        userAccountPwdGet({
          account: `+${this.phoneType}-${this.form.account}`,
        }).then(({ data }) => {
          let { user_id, is_set_passwd } = data;
          this.userId = user_id;
          // if (user_id === 0) {
          //   this.$Message.warning("账号不存在！");
          // }
          if (!is_set_passwd && user_id != 0) {
            this.$refs.passwordRef["open"](user_id);
          }
          resolve(data);
        });
      });
    },
    passwordLogin(password) {
      this.form.password = password;
      this.handleSubmit();
    },
    getCountryList() {
      return new Promise((resolve, reject) => {
        getCountryList()
          .then(({ data, code }) => {
            if (code === 200) {
              let url = window.location.host;
              if (["development"].includes(process.env.NODE_ENV)) {
                url = `https://${this.loginMethod}-test-sg-yeeba.yzg.net`;
              }
              let urlPrefix = url.split(".")[0] || "";
              let item = data.items.find((i) => {
                const regex = new RegExp(i.key, "i");
                return regex.test(urlPrefix);
              });
              this.domainsList = item.domains;
              console.log(this.domainsList);
            }
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
  mounted() {
    if (!isXjb) {
      this.wxLoginCode();
    }
  },
});
</script>
<style lang="less">
// @import '../account';
@import "./index";
.wxlogin-code iframe {
  // height: 280px;
  width: 250px;
  height: 250px;
  overflow: hidden;
  margin: 0 auto 13px;
}
.login_tabs {
  border: 3px solid #f5f8ff;
  background-color: #f5f8ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
  & > div {
    height: 56px;
    width: 176px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #999999;
    cursor: pointer;
  }
  .is_active {
    background-color: #ffffff !important;
    color: #5185ff !important;
  }
}
.input_wrap {
  width: 400px;
  height: 60px;
}
</style>
<style lang="less" scoped>
:deep(.ivu-input-group-prepend) {
  background-color: transparent;
}
:deep(.ivu-input-group-large .ivu-input) {
  height: 60px;
}
:deep(.ivu-input-large) {
  height: 60px;
}
:deep(.ivu-input-wrapper-large .ivu-input-suffix i) {
  line-height: 60px;
}
:deep(.ivu-select-single .ivu-select-selection .ivu-select-selected-value) {
  font-size: 16px;
}

.cur_lang {
  // min-width: 100px;
  text-align: left;
  position: fixed;
  right: 80px;
  top: 80px;
  z-index: 100;
}
</style>

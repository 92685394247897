/*
 * @Description:
 * @Author: luqisheng
 * @Date: 2023-08-22 10:58:54
 * @LastEditTime: 2024-03-25 18:08:37
 * @LastEditors: luqisheng
 */
import { createI18n } from "vue-i18n";
import store from "@/store/index";
store.dispatch("getLocale");
const locale = store["state"].locale;
export default createI18n({
  allowComposition: true,
  globalInjection: true,
  legacy: false,
  locale,
  messages: {
    "zh-CN": require("./zh-CN.json"),
    en: require("./en.json"),
    th: require("./th.json"),
    "zh-TW": require("./zh-TW.json"),
  },
});

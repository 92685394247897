<!--
 * @Description: 
 * @Author: luqisheng
 * @Date: 2023-08-22 10:58:54
 * @LastEditTime: 2024-07-24 09:39:23
 * @LastEditors: luqisheng
-->
<template>
  <div v-if="isMobile || showMobileView" class="home">
    <img src="@/assets/home/mobile-bg.png" width="100%" />
    <div class="footer">
      <div class="footer-container">
        <p class="ip" data-v-023d1e28="">
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            style="color: rgba(255, 255, 255, 0.6)"
            data-v-023d1e28=""
            >粤ICP备2024281763号﹣2</a
          >
        </p>
      </div>
    </div>
  </div>
  <div v-if="isPc" class="home">
    <div class="header">
      <div class="header-container">
        <div class="l-box">
          <div class="logo">
            <img src="~@/assets/home/logo@2x.png" alt="" />
          </div>
          <label>海外酒吧夜店娱乐场所系统</label>
        </div>
        <div class="r-box">
          <div class="nav">
            <div class="nav-item" @click="scrollToElement('#loginBox')">
              {{ $t("page.home.nav.login") }}
            </div>
          </div>
          <Dropdown @on-click="handleCommand">
            <Icon type="ios-globe" />
            <span class="el-dropdown-link">
              {{ curLangName }}
            </span>
            <template #list>
              <DropdownMenu>
                <DropdownItem
                  v-for="item in showLanguageList"
                  :key="item.key"
                  :name="item.key"
                  :selected="locale === item.key"
                >
                  <span>{{ item.label }}</span>
                </DropdownItem>
              </DropdownMenu>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <img src="@/assets/home/bg.png" width="100%" ref="bgRef" />
    <div
      v-if="country"
      class="login-box"
      :style="{ height: loginViewHeight }"
      id="loginBox"
    >
      <!-- <div class="home-box-title">
        「{{ title }}」{{ $t("page.home.background_system") }}
      </div> -->
      <div class="login_tabs">
        <div
          :class="loginMethod == 'z' ? 'is_active' : ''"
          @click="changeLoginTab(3)"
        >
          {{ $t("page.home.loginMethodZ") }}
          <!-- 「咨客台」登录 -->
        </div>
        <div
          :class="loginMethod == 'h' ? 'is_active' : ''"
          @click="changeLoginTab(4)"
        >
          {{ $t("page.home.loginMethodH") }}
          <!-- 「商家后台」登录 -->
        </div>
      </div>
      <div class="login-content">
        <template v-if="avtiveTab == 1">
          <wxlogin
            v-if="loginWxOptions"
            class="wxlogin-code"
            :appid="loginWxOptions.appid"
            scope="snsapi_login"
            :redirect_uri="loginWxOptions.redirect_uri"
            :href="loginWxOptions.href"
          />
          <div class="wxlogin-desc">
            <img src="@/assets/login/ic-wechat.png" class="ic-wechat" />
            <span class="desc">{{ $t("page.home.wx_other") }}</span>
          </div>
        </template>
        <template v-if="avtiveTab == 2">
          <Select
            size="large"
            v-if="showCountryView"
            v-model="country"
            class="country-sel"
            @on-change="countryChange"
          >
            <Option
              v-for="item in countryList"
              :key="item.key"
              :label="`${item.value}(${item.key})`"
              :value="item.key"
            >
              {{ item.value }}({{ item.key }})
            </Option>
          </Select>
          <Input
            v-model="form.account"
            size="large"
            :placeholder="$t('page.home.phone_msg')"
            :style="accountStyles"
            class="input_wrap"
            @on-blur="userAccountPwd"
          >
            <template #prepend>
              <Select
                v-model="phoneType"
                style="width: 90px"
                @on-change="userAccountPwd"
              >
                <Option
                  style="float: left"
                  v-for="item in phoneTypeList"
                  :key="item.code"
                  :label="`+${item.code}`"
                  :value="item.code"
                >
                  +{{ item.code }} {{ item.country }}
                </Option>
              </Select>
            </template>
          </Input>
          <Input
            v-model="form.password"
            type="password"
            size="large"
            password
            :placeholder="$t('page.home.password_msg')"
            style="margin-top: 30px"
            class="input_wrap"
          />
          <Button
            type="primary"
            size="large"
            style="margin-top: 40px; display: block"
            class="input_wrap"
            @click="handleSubmit"
          >
            {{ $t("page.home.submit") }}
          </Button>
        </template>
      </div>
    </div>
    <div class="footer">
      <div class="footer-container">
        <p class="ip" data-v-023d1e28="">
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            style="color: rgba(255, 255, 255, 0.6)"
            data-v-023d1e28=""
            >粤ICP备2024281763号﹣2</a
          >
        </p>
      </div>
    </div>
    <password ref="passwordRef" @confirm="passwordLogin"></password>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { LoginConfig } from "@/config";
import password from "./components/password.vue";
import wxlogin from "vue-wxlogin";
import { mapState, mapActions } from "vuex";
// import mixins from "../mixins";
import { isXjb } from "@/utils";
import { phoneCodeLictGet, userAccountPwdGet, getCountryList } from "@/api/sys";

export default defineComponent({
  name: "page-account-login",
  metaInfo: {
    title: "hiyu.cc酒馆Saas管理系统",
    meta: [
      {
        name: "keyWords",
        content: "缔 造 有 灵 魂",
      },
      {
        name: "description",
        content: "有温度的夜店智能管理系统",
      },
    ],
  },
  //   mixins: [mixins],
  components: { wxlogin, password },
  computed: {
    ...mapState(["token", "locale", "languageList"]),
    showLanguageList() {
      return this.languageList.filter((i) => i.key !== this.locale);
    },
    curLangName() {
      let item = this.languageList.find((i) => this.locale === i.key);
      let name = item ? item.label : "";
      return name;
    },
    title() {
      return isXjb() ? "hiyu.cc" : "小娱儿";
    },
    domainsList() {
      let list = [];
      let item = this.countryList.find((i) => i.key === this.country);
      if (item) {
        list = item.domains;
      }
      return list;
    },
    // logoUrl() {
    //   return isXjb()
    //     ? require("@/assets/home/logo-and-text2.png")
    //     : require("@/assets/home/logo-and-text.png");
    // },
    isMobile() {
      return this.screenWidth < 800;
    },
    isPc() {
      return this.screenWidth > 800;
    },
    showCountryView() {
      let show = true;
      if (
        process.env.VUE_APP_ENV === "yee-pro" &&
        ![
          "www.hiyu.cc",
          "www.hiyu.club",
          "www.yee.ba",
          "hiyu.cc",
          "hiyu.club",
          "yee.ba",
        ].includes(window.location.host)
      ) {
        show = false;
      }
      return show;
    },
    loginViewHeight() {
      let height = this.isXjb ? 494 : 557;
      if (!this.showCountryView) {
        height -= 84;
      }
      return height + "px";
    },
    accountStyles() {
      let styles = "margin-top: 30px; height: 60px";
      if (!this.showCountryView) {
        styles -= "height: 60px";
      }
      return styles;
    },
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      country: null,
      autoLogin: true,
      loginWxOptions: null,
      phoneType: "86",
      phoneTypeList: [],
      form: {
        account: "", // 手机号
        password: "", // 密码
      },
      setPassword: "", // 设置密码
      userId: 0, // 用户id
      avtiveTab: isXjb() ? 2 : 1, // 微信扫码: 1; 账号登录: 2;
      isXjb: isXjb(),
      loginMethod: "h", // h:商户后台登录， z：咨客台登录
      // domainsList: null,
      showMobileView: false,
    };
  },
  created() {
    console.log(this.$store, "是否为国际版", isXjb());
    this.getCountryList();
    this.phoneCodeLic();
    this.$store.dispatch("getLanguageList");
  },
  methods: {
    ...mapActions(["setLocale", "login"]),
    countryChange(e) {
      localStorage.setItem("country", e);
    },
    scrollToElement(elementId) {
      // 平滑滚动到元素
      this.$nextTick(() => {
        const element = document.getElementById(elementId.slice(1));
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      });
    },
    handleCommand(locale) {
      if (locale === this.locale) return;
      this.setLocale({ locale, vm: this });
    },
    /**
     * @description 登录
     * 表单校验已有 View UI Plus 自动完成，如有需要修改，请阅读 View UI Plus 文档
     */
    handleSubmit() {
      let url = "";
      let item = this.domainsList.find((i) => this.loginMethod === i.platform);
      if (item && item.domain) {
        url = item.domain;
      } else {
        this.$Message.warning("登录异常");
        return;
      }
      this.userAccountPwd();
      const { account, password } = this.form;
      if (account == "") {
        this.$Message.error(this.$t("page.home.phone_msg"));
        return;
      }
      if (password == "") {
        this.$Message.error(this.$t("page.home.password_msg"));
        return;
      }
      this.login({
        account: `+${this.phoneType}-${account}`,
        password,
      }).then(() => {
        // 重定向对象不存在则返回顶层路径
        setTimeout(() => {
          window.open(`${url}/#/?token=${this.token}`, "_self");
        }, 200);
      });
    },
    wxLoginCode() {
      var content = `.impowerBox .qrcode {width: 240px;margin-top:0;border:none;}.impowerBox .title {display: none;}.impowerBox .info {display: none;}`;
      var blob = new Blob([content], {
        type: "text/css;charset=utf-8",
      });
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      let that = this;
      reader.onload = function () {
        that.loginWxOptions = {
          appid: LoginConfig.LOGIN_APPID,
          redirect_uri: LoginConfig.LOGIN_REDIRECT_URI,
          href: this.result,
        };
      };
    },
    changeLoginTab(type) {
      switch (type) {
        case 3:
          this.loginMethod = "z";
          break;
        case 4:
          this.loginMethod = "h";
          break;

        default:
          this.avtiveTab = type;
          break;
      }
    },
    phoneCodeLic() {
      return new Promise((resolve) => {
        phoneCodeLictGet({ country: window.location.host }).then(({ data }) => {
          let defItem = data.items.find((i) => i.is_default) || data.items[0];
          let storePhoneCode = localStorage.getItem("phoneCode");
          this.phoneTypeList = data.items || [];
          let phoneType = defItem ? defItem.code : "";
          if (storePhoneCode) {
            phoneType = storePhoneCode;
          }
          this.phoneType = phoneType;
          console.log(storePhoneCode, "---storePhoneCode");
          console.log(defItem, "---默认号码前缀");
          resolve(data);
        });
      });
    },
    userAccountPwd(e) {
      console.log(e, "---e");
      if (e && typeof e === "string") {
        localStorage.setItem("phoneCode", e || "");
      }
      return new Promise((resolve) => {
        userAccountPwdGet({
          account: `+${this.phoneType}-${this.form.account}`,
        }).then(({ data }) => {
          let { user_id, is_set_passwd } = data;
          this.userId = user_id;
          // if (user_id === 0) {
          //   this.$Message.warning("账号不存在！");
          // }
          if (!is_set_passwd && user_id != 0) {
            this.$refs.passwordRef["open"](user_id);
          }
          resolve(data);
        });
      });
    },
    passwordLogin(password) {
      this.form.password = password;
      this.handleSubmit();
    },
    getCountryList() {
      return new Promise((resolve, reject) => {
        getCountryList({ country: window.location.host })
          .then(({ data, code }) => {
            if (code === 200) {
              // let url = window.location.host;
              // if (["development"].includes(process.env.NODE_ENV)) {
              //   url = `https://${this.loginMethod}-test-sg-yeeba.yzg.net`;
              // }
              // let urlPrefix = url.split(".")[0] || "";
              // let item = data.items.find((i) => {
              //   const regex = new RegExp(i.key, "i");
              //   return regex.test(urlPrefix);
              // });
              // this.domainsList = item.domains;
              let country = localStorage.getItem("country");
              this.countryList = data.items;
              this.country = country || this.countryList[0].key;
              // console.log(this.domainsList);
            }
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
  mounted() {
    if (!isXjb) {
      this.wxLoginCode();
    }
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
        this.showMobileView = this.screenWidth === 800;
        console.log(that.screenWidth, "that.screenWidth");
      })();
    };
    setTimeout(() => {
      if (this.screenWidth === 800) {
        this.showMobileView = true;
      }
    }, 2000);
  },
});
</script>
<style lang="less">
// @import '../account';
@import "./index";
.wxlogin-code iframe {
  // height: 280px;
  width: 250px;
  height: 250px;
  overflow: hidden;
  margin: 0 auto 13px;
}
.login_tabs {
  border: 3px solid #f5f8ff;
  background-color: #f5f8ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
  & > div {
    height: 56px;
    width: 176px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #999999;
    cursor: pointer;
  }
  .is_active {
    background-color: #ffffff !important;
    color: #5185ff !important;
  }
}
.input_wrap {
  width: 400px;
  height: 60px;
}
</style>
<style lang="less" scoped>
:deep(.ivu-input-group-prepend) {
  background-color: transparent;
}
:deep(.ivu-input-group-large .ivu-input) {
  height: 60px;
}
:deep(.ivu-input-large, ) {
  height: 60px;
}
:deep(.ivu-input-wrapper-large .ivu-input-suffix i) {
  line-height: 60px;
}
:deep(.ivu-select-single .ivu-select-selection .ivu-select-selected-value) {
  font-size: 16px;
}

.cur_lang {
  // min-width: 100px;
  text-align: left;
  position: fixed;
  right: 80px;
  top: 80px;
  z-index: 100;
}
:deep(.country-sel) {
  &.ivu-select-large.ivu-select-single .ivu-select-selection {
    height: 60px;
  }
  text-align: left;
  &.ivu-select-large.ivu-select-single {
    .ivu-select-selection .ivu-select-placeholder,
    .ivu-select-selection .ivu-select-selected-value {
      height: 60px;
      line-height: 60px;
    }
  }
}
</style>
